.relatedproducts{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 90vh;
    margin-bottom: 170px;
}
.relatedproducts>h1{
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}
.relatedproducts>hr{
    width: 200px;
    height: 0.5px;
    border-radius: 10px;
    border: 2.5px solid #252525;
    background: #252525;
}
.relatedproducts-items{
    margin-top: 50px;
    display: flex;
    gap: 30px;
}

@media (max-width: 1280px){
    .relatedproducts{
        height: 70vh;
    }
    .relatedproducts>h1{
        font-size: 40px;
    }
    .relatedproducts>hr{
        width: 160px;
        border: 2.5px solid #252525;
    }
    .relatedproducts-items{
        gap: 20px;
        margin-top: 30px;
    }
}

@media (max-width: 1024px){
    .relatedproducts{
        height: 60vh;
    }
    .relatedproducts>h1{
        font-size: 30px;
    }
    .relatedproducts>hr{
        width: 120px;
        border: 1.5px solid #252525;
    }
    .relatedproducts-items{
        gap: 10px;
        margin-top: 20px;
    }
}

@media (max-width: 800px){
    .relatedproducts{
        height: 50vh;
        gap: 6px;
    }
    .relatedproducts>h1{
        font-size: 20px;
    }
    .relatedproducts>hr{
        width: 100px;
        border: 1px solid #252525;
    }
    .relatedproducts-items{
        gap: 5px;
        margin-top: 10px;
    }
}

@media (max-width: 500px){
    .relatedproducts{
        height: 80vh;
        margin-bottom: 280px;
    }
    .relatedproducts>h1{
        font-size: 20px;
    }
    .relatedproducts>hr{
        width: 100px;
        border: 1px solid #252525;
    }
    .relatedproducts-items{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}