.navbar{
    display: flex;
    justify-content: space-around;
    padding: 16px;
    box-shadow: 0 1px 3px -2px #000;
    align-items: center;
}
.nav-logo{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.nav-logo>img{
    width: 50px;
}
.nav-logo>p{
    color: #171717;
    font-size: 38px;
    font-weight: 600;
}
.nav-menu{
    display: flex;
    align-items: center;
    gap: 50px;
    list-style: none;
    color: #626262;
    font-size: 20px;
    font-weight: 500;
}
.nav-menu>li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}
.nav-menu>li>hr{
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #ff4141;
}
.nav-login-cart{
    display: flex;
    align-items: center;
    gap: 45px;
}
.btn{
    width: 157px;
    height: 58px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: #515151;
    font-size: 20px;
    font-weight: 500;
    background: #fff;
    cursor: pointer;
}
.nav-login-cart>button:active{
    background: #f3f3f3;
}
.nav-cart-count{
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
    margin-left: -55px;
    border-radius: 50%;
    font-size: 14px;
    background-color: red;
    color: #fff;
}

.nav-dropdown{
    display: none;
}

@media (max-width : 1280px){
    .navbar{
        padding: 12 50px;
    }
    .nav-logo>img{
        width: 40px;
    }
    .nav-logo>p{
        font-size: 25px;
    }
    .nav-menu{
        gap: 30px;
        font-size: 16px;
    }
    .nav-login-cart{
        gap: 30px;
    }
    .btn{
        width: 120px;
        height: 45px;
        font-size: 16px;
    }
    .nav-cart-count{
        margin-left: -40px;
        font-size: 12px;
    }
}

@media (max-width : 1024px){
    .navbar{
        padding: 12px 30px;
    }
    .nav-menu{
        gap: 25px;
        font-size: 14px;
    }
    .btn{
        width: 80px;
        height: 35px;
        font-size: 14px;
    }
    .nav-login-cart>img{
        width: 30px;
    }
    .nav-cart-count{
        width: 18px;
        height: 18px;
    }
}

@media (max-width : 800px){
    .navbar{
        padding: 10px 0;
    }
    .nav-dropdown{
        display: block;
        width: 30px;
        rotate: -90deg;
    }
    .nav-menu{
        display: none;
        height: 80px;
        width: 100%;
        position: absolute;
        background-color: #fff;
        justify-content: center;
        top: 50px;
    }
    .nav-menu-visible{
        display: flex;
    }
    .nav-dropdown.open{
        transform: rotate(90deg);
    }
}

@media (max-width : 500px){
    .navbar{
        padding: 8px 0;
        gap: 3px;
    }
    .nav-logo{
        transform: scale(0.7);
    }
    .nav-logo>img{
        width: 30px;
    }
    .nav-menu{
        height: 70px;
        top: 30px;
    }
    .nav-dropdown{
        width: 16px;
    }
    .nav-login-cart{
        transform: scale(0.7);
    }
    .nav-menu-visible{
        margin-top: 10px;
    }
    .nav-menu{
        top: 30px;
    }
}