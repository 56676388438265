.popular{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: 90vh;
}
.popular>h1{
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}
.popular>hr{
    width: 200px;
    height: 0.5px;
    border: 3px solid #252525;
    border-radius: 10px;
    background: #252525;
}
.popular-item{
    margin-top: 30px;
    display: flex;
    gap: 30px;
}

@media (max-width: 1280px){
    .popular{
        height: 70vh;
    }
    .popular>h1{
        font-size: 40px;
    }
    .popular>hr{
        width: 160px;
        border: 2.5px solid #252525;
    }
    .popular-item{
        gap: 20px;
        margin-top: 30px;
    }
}

@media (max-width: 1024px){
    .popular{
        height: 60vh;
    }
    .popular>h1{
        font-size: 30px;
    }
    .popular>hr{
        width: 120px;
        border: 1.5px solid #252525;
    }
    .popular-item{
        gap: 10px;
        margin-top: 20px;
    }
}

@media (max-width: 800px){
    .popular{
        height: 50vh;
        gap: 6px;
    }
    .popular>h1{
        font-size: 20px;
    }
    .popular>hr{
        width: 100px;
        border: 1px solid #252525;
    }
    .popular-item{
        gap: 5px;
        margin-top: 10px;
    }
}

@media (max-width: 500px){
    .popular{
        height: 80vh;
    }
    .popular>h1{
        font-size: 20px;
    }
    .popular>hr{
        width: 100px;
        border: 1px solid #252525;
    }
    .popular-item{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}