.productdisplay{
    display: flex;
    margin: 0 170px;
    align-items: center;
}
.productdisplay-left{
    display: flex;
    gap: 17px;
}
.productdisplay-img-list{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.productdisplay-img-list>img{
    height: 163px;
}
.productdisplay-main-img{
    width: 586px;
    height: 700px;
}

.productdisplay-right{
    margin: 0 70px;
    display: flex;
    flex-direction: column;
}

.productdisplay-right>h1{
    color: #3d3d3d;
    font-size: 40px;
    font-weight: 700;
}
.productdisplay-star{
    display: flex;
    align-items: center;
    margin-top: 13px;
    gap: 5px;
    color: #1c1c1c;
    font-size: 16px;
}
.productdisplay-star>img{
    width: 20px;
}
.productdisplay-prices{
    display: flex;
    margin: 40px 0;
    gap: 30px;
    font-size: 24px;
    font-weight: 700;
}
.productdisplay-old-price{
    color: #818181;
    text-decoration: line-through;
}
.productdisplay-new-price{
    color: #ff4141;
}
.productdisplay-size>h1{
    margin-top: 55px;
    color: #656565;
    font-size: 20px;
    font-weight: 600;
}
.productdisplay-sizechart{
    display: flex;
    margin: 30px 0;
    gap: 20px;
}
.productdisplay-sizechart>div{
    padding: 18px 24px;
    background: #fbfbfb;
    border:1px solid #ebebeb;
    border-radius: 3px;
    cursor: pointer;
}
.productdisplay-right>button{
    padding: 20px 40px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #ff4141;
    margin-bottom: 40px;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
}
.productdisplay-category{
    margin-top: 10px;
}
.productdisplay-category>span{
    font-weight: 600;
}

@media (max-width: 1280px){
    .productdisplay{
        margin: 0 60px;
    }
    .productdisplay-left{
        gap: 10px;
    }
    .productdisplay-img-list{
        gap: 10px;
    }
    .productdisplay-img-list>img{
        height: 120px;
    }
    .productdisplay-main-img{
        width: auto;
        height: 510px;
    }
    .productdisplay-right{
        margin: 0 30px;
    }
    .productdisplay-right>h1{
        font-size: 22px;
    }
    .productdisplay-star{
        gap: 3px;
        font-size: 13px;
    }
    .productdisplay-prices{
        margin: 10px 0;
        font-size: 18px;
    }
    .productdisplay-description{
        font-size: 13px;
    }
    .productdisplay-size>h1{
        margin-top: 20px;
        font-size: 20px;
    }
    .productdisplay-sizechart>div{
        padding: 14px 20px;
    }
    .productdisplay-right>button{
        width: 150px;
        padding: 15px 0;
        margin-bottom: 20px;
    }
    .productdisplay-category{
        margin-top: 5px;
        font-size: 14px;
    }
}

@media (max-width: 1024px){
    .productdisplay{
        margin: 0 30px;
    }
    .productdisplay-left{
        gap: 5px;
    }
    .productdisplay-img-list>img{
        height: 80px;
    }
    .productdisplay-main-img{
        height: 350px;
    }
    .productdisplay-right>h1{
        font-size: 18px;
    }
    .productdisplay-star>img{
        width: 15px;
    }
    .productdisplay-description{
        font-size: 12px;
    }
    .productdisplay-size{
        margin: 10px 0;
    }
    .productdisplay-sizechart>div{
        padding: 10px 16px;
        font-size: 12px;
    }
    .productdisplay-right>button{
        width: 120px;
        padding: 10px 0;
        margin-bottom: 10px;
        font-size: 14px;
    }
    .productdisplay-category{
        font-size: 12px;
    }
}

@media (max-width: 800px){
    .productdisplay{
        margin: 0 10px;
    }
    .productdisplay-left{
        gap: 0px;
        flex-direction: column-reverse;
        align-items: center;
    }
    .productdisplay-img-list{
        flex-direction: row;
    }
    .productdisplay-img-list>img{
        height: 70px;
    }
    .productdisplay-main-img{
        height: 300px;
    }
    .productdisplay-right>h1{
        font-size: 14px;
    }
    .productdisplay-star>img{
        width: 13px;
    }
    .productdisplay-description{
        font-size: 10px;
    }
    .productdisplay-size{
        margin: 10px 0;
    }
    .productdisplay-size>h1{
        margin-top: 10px 0;
    }
    .productdisplay-sizechart{
        margin-top: 10px;
        gap: 5px;
    }
    .productdisplay-sizechart>div{
        padding: 4px 11px;
    }
    .productdisplay-right>button{
        width: 100px;
        font-size: 12px;
    }
    .productdisplay-category{
        font-size: 10px;
    }
}

@media (max-width: 500px){
    .productdisplay{
        flex-direction: column;
    }
    .productdisplay-left{
        gap: 10px;
        flex-direction: row;
        margin: auto;
    }
    .productdisplay-img-list{
        flex-direction: column;
        gap: 8px;
    }
    .productdisplay-img-list>img{
        height: 75px;
    }
    .productdisplay-main-img{
        height: 330px;
    }
    .productdisplay-right{
        margin: 5px;
    }
    .productdisplay-right>h1{
        margin-top: 15px;
        font-size: 20px;
        font-weight: 500;
    }
    .productdisplay-star>img{
        width: 15px;
    }
    .productdisplay-size{
        gap: 10px;
        margin: 20px 0;
    }
    .productdisplay-sizechart>div{
        padding: 10px 16px;
        font-size: 14px;
    }
    .productdisplay-right>button{
        width: 130px;
        font-size: 15px;
        padding: 12px 0;
    }
    .productdisplay-category{
        font-size: 16px;
    }
}