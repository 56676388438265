.cartitems{
    margin: 100px 170px;
}

.cartitems>hr{
    height: 3px;
    background: #e2e2e2;
    border: none;
}
.format-main{
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 75px;
    padding: 20px 0;
    color: #454545;
    font-size: 18px;
    font-weight: 600;
}

.format{
    font-size: 17px;
    font-weight: 500;
}
.product-icon{
    height: 62px;
}
.remove-icon{
    width: 15px;
    margin: 0px 40px;
    cursor: pointer;
}
.quantity{
    width: 64px;
    height: 50px;
    border: 2px solid #ebebeb;
    background: #fff;
}
.format-hr{
    height: 3px;
    background: #e2e2e2;
    border: none;
}

.cartitems-down{
    display: flex;
    margin: 100px 0;
}
.total{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 200px;
    gap: 40px;
}
.total-item{
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}
.total>button{
    width: 262px;
    height: 58px;
    outline: none;
    border: none;
    background: #ff5a5a;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.promocode{
    flex: 1;
    font-size: 16px;
    font-weight: 500;
}
.promocode>p{
    color: #555;
}
.promobox{
    width: 504px;
    margin-top: 15px;
    padding-left: 20px;
    height: 58px;
    background: #eaeaea;
    display: flex;
    align-items: center;
}
.promobox>input{
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    width: 330px;
    height: 50px;
}
.promobox>button{
    width: 170px;
    height: 58px;
    border: none;
    outline: none;
    color: #fff;
    background-color: #000;
    font-size: 16px;
    cursor: pointer;
}

@media (max-width: 1280px){
    .cartitems{
        margin: 60px 50px;
    }
    .format-main{
        grid-template-columns: 0.5fr 3fr 0.5fr 0.5fr 0.5fr 0.5fr;
        gap: 20px;
        padding: 15px 0;
        font-size: 15px;
    }
    .product-icon{
        height: 50px;
    }
    .remove-icon{
        margin: auto;
    }
    .quantity{
        width: 40px;
        height: 30px;
    }

    .cartitems-down{
        margin: 80px 0;
        flex-direction: column;
        gap: 80px;
    }
    .total{
        margin: 0;
    }
    .total>button{
        min-width: 200px;
        height: 45px;
        font-size: 13px;
    }
    .promobox{
        width: auto;
        max-width: 500px;
    }
    .promobox>input{
        width: 100%;
    }
    .promobox>button{
        width: 120px;
        margin-left: -125px;
    }
}

@media (max-width: 800px){
    .format-main{
        display: none;
    }
    .format{
        display: grid;
        grid-template-columns: 0.5fr 3fr 0.5fr;
        gap: 10px;
    }
}