.items{
    width: 280px;
    margin-top: 30px;
}
.item-image{
    width: 280px;
    height: 380px;
    object-fit: contain;
}
.items>p{
    margin: 6px 0px;
}
.items-prices{
    display: flex;
    gap: 20px;

}
.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}
.item-price-old{
    color: #374151;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.items:hover{
    transform: scale(1.05);
    transition: 0.6s;
}

@media (max-width: 1280px){
    .items{
        width: 220px;
        font-size: 14px;
    }
    .item-image{
        width: 220px;
        height: 340px;
    }
    .item-price-old{
        font-size: 14px;
    }
}

@media (max-width: 1024px){
    .items{
        width: 170px;
        font-size: 13px;
    }
    .item-image{
        width: 170px;
        height: 300px;
    }
    .item-price-old{
        font-size: 13px;
    }
}

@media (max-width: 800px){
    .items{
        width: 120px;
        font-size: 12px;
    }
    .item-image{
        width: 120px;
        height: 220px;
    }
    .item-price-old{
        font-size: 12px;
    }
}

@media (max-width: 500px){
    .items{
        width: 160px;
        margin-top: 20px;
    }
    .item-image{
        width: 160px;
        height: 240px;
    }
}