.offers{
    width: 65%;
    height: 60vh;
    display: flex;
    margin: auto;
    padding: 0px 140px;
    margin-top: 100px;
    margin-bottom: 150px;
    background: linear-gradient(180deg, #fde1ff 0%, #e1ffea22 60%);
}

.offers-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.offers-left>h1{
    color: #171717;
    font-size: 60px;
    font-weight: 500;
}
.offers-left>p{
    color: #171717;
    font-size: 22px;
    font-weight: 500;
}
.offers-left>button{
    width: 282px;
    height: 70px;
    border-radius: 35px;
    background-color: #ff4141;
    border: none;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    margin-top: 30px;
    cursor: pointer;
}
.offers-right{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 50px;
    position: relative;
}

.offers-right>img{
    object-fit: contain;
    height: 80%;
    width: 350px;
    position: absolute;
    top: 50px;
    right: -20px;
}

@media (max-width: 1280px){
    .offers{
        padding: 0px 80px;
        margin-bottom: 120px;
    }
    .offers-left>h1{
        font-size: 40px;
    }
    .offers-left>p{
        font-size: 18px;
    }
    .offers-left>button{
        width: 220px;
        height: 50px;
        font-size: 18px;
    }
    .offers-right{
        padding-top: 30px;
    }

    .offers-right>img{
        width: 300px;
    }

}

@media (max-width: 1024px){
    .offers{
        padding: 0px 60px;
        margin-bottom: 80px;
        height: 50vh;
    }
    .offers-left>h1{
        font-size: 30px;
    }
    .offers-left>p{
        font-size: 16px;
    }
    .offers-left>button{
        width: 160px;
        height: 40px;
        font-size: 16px;
    }
    .offers-right{
        padding-top: 30px;
    }

    .offers-right>img{
        width: 300px;
    }
}

@media (max-width: 800px){
    .offers{
        margin-top: 150px;
        margin-bottom: 60px;
    }
    .offers-left>h1{
        font-size: 22px;
    }
    .offers-left>p{
        font-size: 13px;
    }
    .offers-left>button{
        width: 140px;
        height: 30px;
        font-size: 13px;
    }
    .offers-right{
        padding-top: 30px;
    }

    .offers-right>img{
        width: 200px;
    }
}

@media (max-width: 500px){
    .offers{
        height: 25vh;
        margin-top: 440px;
        margin-bottom: 50px;
    }
    .offers-left>h1{
        font-size: 16px;
    }
    .offers-left>p{
        font-size: 10px;
    }
    .offers-left>button{
        width: 120px;
        height: 25px;
        font-size: 13px;
    }
    .offers-right{
        padding-top: 30px;
    }

    .offers-right>img{
        width: 150px;
        right: -30px;
    }
}