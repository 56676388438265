.new-collections{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 100px;
}
.new-collections>h1{
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}
.new-collections>hr{
    width: 200px;
    height: 0.5px;
    border: 3px solid #252525;
    border-radius: 10px;
    background: #252525;
}

.collections{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 50px;
    gap: 30px;
}

@media (max-width: 1280px){
    .new-collections>h1{
        font-size: 40px;
    }
    .new-collections>hr{
        width: 160px;
        border: 2.5px solid #252525;
    }
    .collections{
        gap: 20px;
        margin-top: 30px;
    }
}

@media (max-width: 1024px){
    .new-collections>h1{
        font-size: 30px;
    }
    .new-collections>hr{
        width: 120px;
        border: 1.5px solid #252525;
    }
    .collections{
        gap: 15px;
    }
}

@media (max-width: 800px){
    .new-collections{
        gap: 6px;
    }
    .new-collections>h1{
        font-size: 20px;
    }
    .new-collections>hr{
        width: 100px;
        border: 1px solid #252525;
    }
    .collections{
        gap: 5px;
    }
}

@media (max-width: 500px){
    .new-collections>h1{
        font-size: 20px;
    }
    .new-collections>hr{
        width: 100px;
        border: 1px solid #252525;
    }
    .collections{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}