.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.footer-logo{
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer-logo>img{
    width: 64px;
}
.footer-logo>p{
    color: #383838;
    font-size: 46px;
    font-weight: 700;
}
.footer-links{
    display: flex;
    list-style: none;
    gap: 50px;
    color: #252525;
    font-size: 20px;
}
.footer-links>li{
    cursor: pointer;
}
.footer-links>li:hover{
    text-decoration: underline;
}
.social-links{
    display: flex;
    gap: 20px;
}
.social-icon{
    padding: 10px;
    padding-bottom: 6px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    cursor: pointer;
}
.footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-bottom: 30px;
    color: #1a1a1a;
    font-size: 20px;
}
.footer-copyright>hr{
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 3px;
    background: #c7c7c7;
}

@media (max-width: 800px){
    .footer{
        gap: 20px;
    }
    .footer-logo{
        gap: 10px;
        align-items: end;
    }
    .footer-logo>img{
        width: 40px;
    }
    .footer-logo>p{
        font-size: 25px;
    }
    .footer-links{
        font-size: 14px;
        gap: 10px;
    }
    .social-icon>img{
        width: 22px;
    }
    .footer-copyright{
        font-size: 13px;
    }
}